import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faFileInvoice, faTimes } from '@fortawesome/pro-light-svg-icons';

import If from 'bundles/components/common/If';
import { processFieldName } from 'bundles/utils';
import { formatBytes, animationStyle, setErrorClass } from './utils';
import { FileUploadProps } from './types';
import { useFileUpload } from 'bundles/hooks';

import './styles.scss';

const FileUpload = React.forwardRef(({ onChange, ...rest }: FileUploadProps, ref: (arg0: HTMLInputElement) => HTMLInputElement) => {
  const { t } = useTranslation();
  const { handleChange, handleClear, handleDragEnter, handleDragLeave, handleOnDrop, handleFileAnimation, dragEnter, fileAnimation, refInput } = useFileUpload({ onChange });

  return (
    <div className={`file-upload-wrapper ${setErrorClass(rest)} ${dragEnter}`} onDragEnter={handleDragEnter} onDrop={handleOnDrop} onDragLeave={handleDragLeave}>
      <div className="cloud-upload-icon-wrapper">
        <FontAwesomeIcon icon={faCloudUpload} />
      </div>

      <p>
        <span className="file-upload-text">{t('labels.drop_file_here_or_browse.0')}</span>
        <span className="file-browse-link"> {t('labels.drop_file_here_or_browse.1')}{' '}
          <label className="file-upload-label" htmlFor={rest.name}>
            <input
              id={rest.id || rest.name}
              name={rest.name}
              className={`file-upload-field ${setErrorClass(rest)}`}
              accept={rest.accept}
              multiple={rest.multiple}
              type="file"
              onChange={handleChange}
              ref={(e) => {
                ref(e as HTMLInputElement);
                refInput.current = e;
              }}
            />{t('labels.drop_file_here_or_browse.2')}
          </label>
        </span>
      </p>

      <If condition={processFieldName(rest.control._formValues, rest.name)?.length}>
        {[...(processFieldName(rest.control._formValues, rest.name)) || []].map((file, i) =>
          <div key={i}>
            <h2 className="selected-file" style={fileAnimation ? animationStyle : {}} onAnimationEnd={handleFileAnimation}>
              <FontAwesomeIcon icon={faFileInvoice} className=" float-left file-upload-icon" />
              <span className="float-left">{file.name}<small>{t('labels.size')}: {formatBytes(file.size)}</small></span>
              <span className="clear" onClick={() => handleClear(i)}><FontAwesomeIcon icon={faTimes} /></span>
            </h2>
          </div>
        )}
      </If>
    </div>
  );
});

FileUpload.displayName = 'FileUpload';

export default FileUpload;

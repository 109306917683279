import React, { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const withTooltip = (Component: FC<Record<string, any>>) => {
  const Wrapper = (props: any) => {
    const { tooltip, ...restProps } = props;

    return (
      <>
        {tooltip
          ? <OverlayTrigger trigger={['hover', 'click']} overlay={<Tooltip id={`tooltip-id-${tooltip}-${restProps.id}`}>{tooltip}</Tooltip>}>
            <Component {...restProps} />
          </OverlayTrigger>
          : <Component {...restProps} />
        }
      </>
    );
  };

  Wrapper.displayName = Component.displayName;

  return Wrapper;
};
export default withTooltip;

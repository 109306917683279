import { buildFormData } from '@Utils/';

const setUrlParams = (params, withReset = false, ignoreParams = []) => {
  const newSearchParams = new URLSearchParams(buildFormData(params).entries());
  let searchParams = new URLSearchParams(withReset ? '' : window.location.search);
  let finalizedSearchParams = new URLSearchParams();
  let url = '';
  let tabSelection = window.location?.hash || '';

  if (params) {
    for (const p of newSearchParams) {
      searchParams.set(p[0], p[1]);
    }

    for (const p of searchParams) {
      if(ignoreParams.includes(p[0]) || ignoreParams.includes(p[1])) continue;

      finalizedSearchParams.set(p[0], p[1]);
    }

    url = `?${finalizedSearchParams}${tabSelection}`;
  } else {
    url = `${window.location.pathname}${tabSelection}`;
  }

  window.history.replaceState(null, null, url);
};

export default setUrlParams;

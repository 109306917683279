import { useState, useCallback, useEffect } from 'react';

const useDebounce = (callbackFn = () => {}, timeout = 500) => {
  const [sends, setSends] = useState(0);

  const stabilizedCallbackFn = useCallback(callbackFn, [callbackFn]);

  const debounceRequest = () => {
    setSends(sends + 1);
    return sends;
  };

  useEffect(() => {
    if (sends > 0) {
      const id = window.setTimeout(() => {
        stabilizedCallbackFn();
        setSends(0);
      }, timeout);

      return () => window.clearTimeout(id);
    }
  }, [stabilizedCallbackFn, sends, timeout]);

  return { debounceRequest };
};

export default useDebounce;

import React, { FC } from 'react';

const withShow = (Component: FC<Record<string, any>>) => {
  const Wrapper = (props: any) => {
    const { show = true, ...restProps } = props;
    return <>{Boolean(show) === true ? <Component {...restProps} /> : null}</>;
  };

  Wrapper.displayName = Component.displayName;

  return Wrapper;
};
export default withShow;

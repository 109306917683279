//MERCHANTS
export const MERCHANTS_ACCOUNTS = '/merchants/accounts';
export const MERCHANTS_ACCOUNT_REPORT = 'merchants/account/report';
export const MERCHANTS_ACCOUNT_REQUEST_VERIFICATION = '/merchants/account_verification_documents/request_verification';
export const MERCHANTS_ACCOUNT_VERIFICATION_DOCUMENTS = '/merchants/account_verification_documents';
export const MERCHANTS_BANK_ACCOUNTS = '/merchants/bank_accounts';
export const MERCHANTS_BANK_PAYOUTS = '/merchants/bank_payouts';
export const MERCHANTS_BANK_PAYOUTS_NEW = '/merchants/bank_payouts/new';
export const MERCHANTS_BATCH_PAYOUTS = '/merchants/batch_payouts';
export const MERCHANTS_CALCULATE_DEDUCTED_AMOUNT_FOR_BANK_PAYOUT = '/merchants/bank_payouts/convert_payout_amount';
export const MERCHANTS_CONSUMER_BATCHES = '/merchants/consumers_batches';
export const MERCHANTS_DASHBOARD = '/merchants/dashboard';
export const MERCHANTS_DEPOSIT = '/merchants/deposit';
export const MERCHANTS_DEPOSITS = '/merchants/deposits';
export const MERCHANTS_DATA_CHART_REQUEST = '/merchants/dashboard/data_chart_request';
export const MERCHANTS_DOWNLOAD_TRANSACTIONS = '/merchants/download_transactions';
export const MERCHANTS_EMPLOYEES = '/merchants/employees';
export const MERCHANTS_MANAGE_ACCOUNTS = '/merchants/manage_accounts';
export const MERCHANTS_MERCHANT_RESOURCES = '/merchants/merchant_resources';
export const MERCHANTS_MONTHLY_STATEMENTS_REPORT = 'merchants/account/monthly_statements_report';
export const MERCHANTS_PASSWORD = 'merchants/passwords';
export const MERCHANTS_SINGLE_PAYOUTS = '/merchants/single_payouts';
export const MERCHANTS_TRANSACTIONS = '/merchants/transactions';
export const MERCHANTS_TRANSACTIONS_HISTORY = '/merchants/transactions_history';
export const MERCHANTS_TRANSACTIONS_REQUEST = '/merchants/dashboard/transactions_request';
export const MERCHANTS_TRANSFER_BALANCES = '/merchants/transfer_balances';
export const MERCHANTS_WITHDRAWALS = '/merchants/withdrawals';
export const MERCHANTS_WALLET_TO_WALLET_TRANSFERS = '/merchants/wallet_to_wallet_transfers';

//CONSUMERS
export const CONSUMERS_ACCOUNT_DETAILS = '/consumers/account/details';
export const CONSUMERS_ACCOUNT_EMAILS = 'consumers/account/emails';
export const CONSUMERS_ACCOUNT_REPORT = '/consumers/account/report';
export const CONSUMERS_ACCOUNT_UPDATE_TIMEZONE = '/consumers/account/update_timezone';
export const CONSUMERS_ACCOUNT_REQUEST_VERIFICATION = '/consumers/account/request_verification';
export const CONSUMERS_ACCOUNT_STATEMENT_REPORT = 'consumers/account/statement_report';
export const CONSUMERS_ACCOUNT = '/consumers/account';
export const CONSUMERS_ACCOUNTS = '/consumers/accounts';
export const CONSUMERS_ACCOUNT_VERIFICATIONS = '/consumers/account_verifications';
export const CONSUMERS_AVATAR_UPLOAD = '/consumers/account/avatar';
export const CONSUMERS_BANK_ACCOUNTS = '/consumers/bank_accounts';
export const CONSUMERS_DASHBOARD = '/consumers/dashboard';
export const CONSUMERS_DEPOSITS = '/consumers/deposits';
export const CONSUMERS_DOWNLOAD_TRANSACTIONS = '/consumers/download_transactions';
export const CONSUMERS_FUNDING_METHODS = '/consumers/funding_methods';
export const CONSUMERS_PASSWORD = 'consumers/passwords';
export const CONSUMERS_REGISTRATIONS = '/consumers';
export const CONSUMERS_REGISTRATIONS_COUNTRY_SPECIFIC_CONFIG = '/consumers/country_specific_config';
export const CONSUMERS_TERMS_AND_CONDITIONS = '/consumers_terms_and_conditions';
export const BUSINESS_TERMS_AND_CONDITIONS_IOM = '/merchants_terms_and_conditions_iom';
export const BUSINESS_TERMS_AND_CONDITIONS_FITBANK = '/merchants_terms_and_conditions_fitbank';
export const CONSUMERS_TERMS_AND_CONDITIONS_FITBANK = '/consumers_terms_and_conditions_fitbank';
export const CONSUMERS_TRANSACTIONS = '/consumers/transactions';
export const CONSUMERS_TRANSACTIONS_HISTORY = '/consumers/transactions_history';
export const CONSUMERS_WALLET_TO_WALLET_TRANSFERS = '/consumers/wallet_to_wallet_transfers';
export const CONSUMERS_WALLET_TO_WALLET_TRANSFERS_REQUESTS = '/consumers/wallet_to_wallet_transfer_requests';
export const CONSUMERS_WITHDRAWALS = '/consumers/withdrawals';
export const CONSUMERS_SYSTEM_NOTIFICATIONS = '/consumers/system_notifications';
export const CONSUMERS_SYSTEM_NOTIFICATIONS_MARK_ALL_READ = '/consumers/system_notifications/mark_all_read';

//ADMINS
export const ADMINS = '/admins';

//ADMINS/ACCOUNTS
export const ADMINS_ACCOUNTS = '/admins/accounts';
export const ADMINS_REPORTS = '/admins/reports';
export const ADMINS_PASSWORDS = '/admins/passwords';

//ADMINS/CONSUMERS
export const ADMINS_CONSUMERS = '/admins/consumers';
export const ADMINS_CONSUMERS_PASSWORDS = '/admins/consumers/reset_passwords';
export const ADMINS_VERIFICATION_DOCUMENTS = '/admins/account_verification_documents';
export const ADMINS_CONSUMERS_VERIFICATION_DOCUMENTS = '/admins/account_verification_documents/consumer';

//ADMINS/ADMINS
export const ADMINS_ADMINS = '/admins/admins';
export const ADMINS_ADMINS_ROLES_AND_RIGHTS = '/admins/admins/roles_and_rights';

//ADMINS/CAMPAIGNS
export const ADMINS_CAMPAIGNS = '/admins/campaigns';
export const ADMINS_CAMPAIGNS_NEW = '/admins/campaigns/new';

//ADMINS/FRAUD DETECTION
export const ADMINS_FRAUD_DETECTION_DIFFERENT_IPS_SUSPENDED_ACCOUNTS = '/admins/fraud_detection/different_ips_suspended_accounts';
export const ADMINS_FRAUD_DETECTION_DUPLICATE_ACCOUNTS = '/admins/fraud_detection/duplicate_accounts';
export const ADMINS_FRAUD_DETECTION_DUPLICATE_IPS = '/admins/fraud_detection/duplicate_ips';
export const ADMINS_FRAUD_DETECTION_DUPLICATE_FUNDING_METHODS = '/admins/fraud_detection/duplicate_funding_methods';
export const ADMINS_FRAUD_DETECTION_SUSPENDED_ACCOUNTS = '/admins/fraud_detection/suspended_accounts';
export const ADMINS_FRAUD_DETECTION_BANK_BLACKLISTED_IPS = '/admins/fraud_detection/blacklisted_ips';

//ADMINS/CONFIGURATIONS
export const ADMINS_MERCHANT_LIMITS = '/admins/merchant_limits';
export const ADMINS_CONSUMER_LIMITS = '/admins/consumer_limits';
export const ADMINS_CONFIGURATIONS_PROCESSING = '/admins/configurations/processing';
export const ADMINS_CONFIGURATIONS_MISCELLANEOUS = '/admins/configurations/miscellaneous';

//ADMINS/REPORTS
export const ADMINS_REPORTS_BALANCES = '/admins/reports/balances';
export const ADMINS_REPORTS_TRANSIT = '/admins/reports/transit';
export const ADMINS_REPORTS_DEPOSITS = '/admins/reports/deposits';
export const ADMINS_REPORTS_MERCHANT_REVENUE = '/admins/reports/merchant_revenue';
export const ADMINS_REPORTS_TRANSACTIONS = '/admins/reports/transactions';
export const ADMINS_REPORTS_MERCHANT_COUNTRY_VOLUMES = '/admins/reports/merchant_country_volumes';
export const ADMINS_REPORTS_FUNDING_METHODS = '/admins/reports/funding_methods';
export const ADMINS_FX_REPORTS_VOLUMES = '/admins/fx_reports/volumes';
export const ADMINS_INTERNAL_WALLET_BALANCES = '/admins/reports/internal_wallet_balances';

//ADMINS/SYSTEM UTILITIES
export const ADMINS_API_LOGS = '/admins/api_logs';
export const ADMINS_SYSTEM_EMAILS = '/admins/system_emails';
export const ADMINS_SYSTEM_EMAILS_NEW = '/admins/system_emails/new';
export const ADMINS_SYSTEM_EMAILS_SEND = '/admins/system_emails/send_email';
export const ADMINS_EVENT_AUDITS = '/admins/event_audits';
export const ADMINS_SIDEKIQ_SERVER = '/admins/sidekiq_server';
export const ADMINS_SIDEKIQ_JOBS = '/admins/sidekiq_jobs';
export const ADMINS_BALANCE_VALIDATION = '/admins/balance';
export const ADMINS_SYSTEM_MESSAGES = '/admins/system_messages';

export const ADMINS_BUY_RATES = '/admins/buy_rates';
export const ADMINS_BANK_ACCOUNTS = '/admins/bank_accounts';
export const ADMINS_BANK_ACCOUNT_WITHDRAWAL_PROVIDERS = '/admins/bank_account_withdrawal_providers';
export const ADMINS_CARD_BRANDS = '/admins/card_brands';
export const ADMINS_CREDIT_CARD_SUPPORTED_COUNTRIES_EDIT = '/admins/credit_card_supported_countries';
export const ADMINS_COMPAIGNS = '/admins/campaigns';
export const ADMINS_COMPAIGNS_NEW = '/admins/campaigns/new';
export const ADMINS_CONSUMER_BANK_ACCOUNT_WITHDRAWAL_FEES = 'admins/consumer_bank_account_withdrawal_fees';
export const ADMINS_CONSUMER_FEES = 'admins/consumer_fees';
export const ADMINS_CONSUMER_THRESHOLDS = 'admins/consumer_thresholds';
export const ADMINS_CONSUMER_VIP_GROUPS = '/admins/consumer_vip_groups';
export const ADMINS_CONSUMER_VIP_GROUP_DETAILS = 'admins/consumer_vip_group_details';
export const ADMINS_CONSUMER_REVIEW_ACTIONS = '/admins/consumer_review_actions';
export const ADMINS_COUNTRIES = '/admins/countries';
export const ADMINS_COUNTRIES_UPDATE_SUPPORT_STATE = '/admins/countries/update_support_state';
export const ADMINS_COUNTRIES_UPDATE_REGISTRATION_STATE = '/admins/countries/update_registration_state';
export const ADMINS_CURRENCIES = '/admins/currencies';
export const ADMINS_CURRENCIES_UPDATE_ACTIVATION_STATE = '/admins/currencies/update_activation_state';
export const ADMINS_CURRENCIES_UPDATE_REGISTRATION_STATE = '/admins/currencies/update_registration_state';
export const ADMINS_DOWNLOAD_TRANSACTIONS = '/admins/download_transactions';
export const ADMINS_GENESIS_CONFIGURATIONS = '/admins/genesis_configurations';
export const ADMINS_GENESIS_CONFIGURATION_NEW = '/admins/genesis_configurations/new';
export const ADMINS_INTERNAL_BANK_ACCOUNTS = '/admins/internal_bank_accounts';
export const ADMINS_INTERNAL_WALLETS = '/admins/internal_wallets';
export const ADMINS_LEGAL_DOCUMENTS= 'admins/legal_documents';
export const ADMINS_LOYALTY_POINT_SETUPS= 'admins/loyalty_point_setups';
export const ADMINS_MERCHANTS = '/admins/merchants';
export const ADMINS_MERCHANTS_EMPLOYEES = '/admins/merchant_employees';
export const ADMINS_MERCHANTS_NEW = '/admins/merchants/new';
export const ADMINS_MERCHANTS_VERIFICATION_DOCUMENTS = '/admins/account_verification_documents/merchant';
export const ADMINS_MERCHANT_RESOURCES = '/admins/merchant_resources';
export const ADMINS_PENDING_WITHDRAWALS_TO_BANK_ACCOUNTS = '/admins/pending_withdrawals_to_bank_accounts';
export const ADMINS_PREPAID_CARDS = '/admins/prepaid_cards';
export const ADMINS_PROCESS_WITHDRAWALS_TO_BANK_ACCOUNTS = '/admins/process_withdrawals_to_bank_accounts';
export const ADMINS_RECONCILIATIONS_DEPOSITS = '/admins/reconciliation/deposits';
export const ADMINS_RECONCILIATIONS_WITHDRAWALS = '/admins/reconciliation/withdrawals';
export const ADMINS_REFUNDS = '/admins/refunds';
export const ADMINS_REPORTS_CONSUMER_CREATION = '/admins/reports/consumer_creation';
export const ADMINS_FINANCE_BANK_PAYOUTS = '/admins/bank_payouts';
export const ADMINS_FRAUD_DETECTION_DOCUMENT_EXPIRATION = '/admins/fraud_detection/document_expiration';
export const ADMINS_FUNDING_METHOD_CONFIGURATIONS = '/admins/funding_method_configurations';
export const ADMINS_REPORTS_EXPORTS = '/admins/reports/exports';
export const ADMINS_REPORTS_REVENUE = '/admins/reports/revenue';
export const ADMINS_FINANCE_BANK_PAYOUTS_EXPORT_CSV = '/admins/bank_payouts/export_as_csv';
export const ADMINS_REPORTS_WITHDRAWALS = '/admins/reports/withdrawals';
export const ADMINS_REVERT_WITHDRAWALS_TO_BANK_ACCOUNTS = '/admins/revert_withdrawals_to_bank_accounts';
export const ADMINS_TRANSACTIONS_ADJUSTMENTS = '/admins/transaction_adjustments';
export const ADMINS_TRANSACTIONS_CHARGEBACK = '/admins/transaction_chargeback';
export const ADMINS_TRANSACTIONS_HISTORY = '/admins/transactions_history';
export const ADMINS_TRANSACTIONS_REPRESENTMENT = '/admins/transaction_representment';
export const ADMINS_UNDER_REVIEW_CONSUMER_REGISTRATIONS = '/admins/under_review_consumer_registrations';
export const MERCHANT_COUNTRY_VOLUMES = '/admins/reports/merchant_country_volumes';

//COMMON
export const BANK_ACCOUNT_CODE_MAPPINGS = '/bank_account_code_mappings';
export const BRAZIL_TERMS_AND_CONDITIONS = 'https://dlocal.com/legal/brazil/termos-e-condicoes-para-credenciamento-ao-sistema-dlocal/';
export const CANCEL = '/cancel';
export const EDIT = '/edit';
export const FEES = '/fees';
export const FX_EXCHANGES = '/fx_exchanges';
export const LOYALTY_POINTS_CONVERT = '/loyalty_points/convert';
export const NEW = '/new';
export const PRIVACY_POLICY = '/privacy_policy';
export const PRIVACY_POLICY_FITBANK = '/privacy_policy_fitbank';
export const SESSION = '/session';
export const SESSION_NEW = '/session/new';
export const SESSION_VERIFY = '/session/verify';
export const TERMS_OF_USE = '/terms_of_use';
export const TERMS_OF_USE_FITBANK = '/terms_of_use_fitbank';
export const TINYMCE_ASSETS_CREATE = '/tinymce_assets/create';
export const MONTHLY_STATEMENTS_REPORT = '/monthly_statements_report';
export const STATEMENT_REPORT = '/statement_report';

//PUBLIC
export const ABOUT_US = '/about_us';
export const AFFILIATE = '/affiliate';
export const API = 'https://emerchantpay.github.io/ewallet-api-docs';
export const BUSINESS = '/business';
export const BUSINESS_TERMS_AND_CONDITIONS = '/merchants_terms_and_conditions';
export const COMPLAINTS_HANDLING_POLICY = '/complaints_handling_policy';
export const CONSUMERS_NEW = '/consumers/new';
export const CONSUMERS_REGISTRATIONS_NEW = '/consumers/registrations/new';
export const COOKIE_POLICY = '/cookie_policy';
export const COOKIE_SETTINGS = '/cookie_settings';
export const DOCUMENTATION_HISTORY = '/documentation_history';
export const EZEEWALLET_VIP_PROGRAMME = '/vipclub';
export const FAQ = '/faqpage';
export const FACEBOOK = 'https://facebook.com/ezeewallet';
export const FINANCIAL_OMBUDSMAN = 'https://www.financial-ombudsman.org.uk';
export const FEES_LIMITS = '/fees_and_limits';
export const HOME_PAGE = '/';
export const MERCHANT_INTEGRATION_GUIDE = '/merchant_integration_guide';
export const MERCHANTS_REGISTRATIONS = '/merchants/registrations';
export const MERCHANTS_REGISTRATIONS_NEW = '/merchants/registrations/new';
export const MODERN_SLAVERY_AND_HUMAN_TRAFFICKING_STATEMENT = '/modern_slavery_and_human_trafficking_statement';
export const VIP_TERMS_AND_CONDITIONS = '/vip_terms_and_conditions';
export const PERSONAL = '/personal';
export const RESET_PASSWORD = '/reset_passwords';
export const RESET_PASSWORD_NEW = '/reset_passwords/new';
export const REFER_A_FRIEND = '/referfriend';
export const REQUEST = 'https://support.ezeewallet.com/hc/en-us/requests/new';
export const SECURITY_OF_CLIENT_FUNDS = '/security_of_client_funds';
export const STATUS_PAGE = 'https://status.ezeewallet.com';
export const SUPPORT = 'https://support.ezeewallet.com/hc/en-us';
export const THE_VIP_CLUB = '/vipclub';
export const TWITTER = 'https://twitter.com/ezeewallet';
export const ZENDESK = 'https://ezeewallet.zendesk.com/hc/en-us';

import React, { useEffect, useContext } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import If from 'bundles/components/common/If';
import { FilterContext } from 'bundles/contexts/FilterContext/FilterContextProvider';
import { FilterContextInterface } from 'bundles/contexts/FilterContext/types';
import { ComponentInterface } from './types';
import TableCardHeader from './TableCardHeader';
import { setUrlParams  } from 'bundles/utils';
import { getPageData } from './utils';
import PageHeader from 'bundles/components/common/PageHeader';
import Pagination from 'bundles/components/common/Pagination';
import PlaceHolder from 'bundles/components/common/PlaceHolder';
import Filter from './Filter';
import HeaderActions from './HeaderActions';
import { IPagination } from 'bundles/components/common/Pagination/types';

import './styles.scss';

const Component = ({ FilterContent, AddCriteriaContent, Table, TableHeaderTitle, HeaderActionsContent }: ComponentInterface) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { state, dispatch } = useContext<FilterContextInterface>(FilterContext);

  const handleClickPage = (page: number) => {
    setUrlParams({ page: page });
    getPageData(state, dispatch, { tableRowAnimated: '' });
  };

  useEffect(() => { state.shouldRequestOnMount !== false && getPageData(state, dispatch); }, []);

  return (
    <FormProvider {...methods}>
      <If condition={state.page_title || HeaderActionsContent}>
        <PageHeader title={state.page_title}><HeaderActions HeaderActionsContent={HeaderActionsContent} /></PageHeader>
      </If>

      <Alert show={Boolean(state.fitbank_flash?.message)} variant={state.fitbank_flash?.type}>{state.fitbank_flash?.message}</Alert>

      <If condition={state.inline_message}>
        <span dangerouslySetInnerHTML={{ __html: state.inline_message || '' }} />
      </If>

      <If condition={FilterContent || AddCriteriaContent}>
        <Filter {...{ FilterContent, AddCriteriaContent, methods, state, dispatch }} />
      </If>

      <Card>
        <If condition={TableHeaderTitle}>
          <TableCardHeader TableHeaderTitle={TableHeaderTitle} />
        </If>
        <Card.Body className="p-0 position-relative">
          <If condition={state.collection?.length}>
            <Table collection={state.collection} total={state.total} variant={state.tableRowAnimated} hiddenColumns={state.hiddenColumns} />
          </If>
          <If condition={state.collection && !state.collection?.length}>
            <div className="text-center no-data-message">{state.no_data_message || t('labels.no_data_found')}</div>
          </If>

          <PlaceHolder.Text type="paragraph" className="ml-auto mr-auto w-50 p-5 placeholder-hoc-filter-pagination-csv" condition={state.collection === undefined} />
        </Card.Body>

        <If condition={state.collection?.length && state.pagination}>
          <Card.Footer>
            <Pagination handleClickPage={handleClickPage} pagination={state.pagination as IPagination} />
          </Card.Footer>
        </If>
      </Card>
    </FormProvider>
  );
};

export default Component;

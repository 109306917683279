import React from 'react';

import { i18n } from 'bundles/utils';
import If from 'bundles/components/common/If';
import { FormFieldInterface, SelectOptionsType } from './types';

const Select = React.forwardRef(({ options, withSelectOption = true, disabledWithSkipValidation, register, ...rest }: FormFieldInterface, ref: (instance: HTMLSelectElement | null) => void | (() => void)) => {

  const isEmptyArray = (Array.isArray(options) && !options.length);
  const isArrayWithoutSelectOption = options && (options[0] && ((options[0] as Array<any>)?.[1] || (options[0] as Record<string, any>)?.select_options_value));
  const shouldAddSelectOption = !rest.placeholder && withSelectOption && (isEmptyArray || isArrayWithoutSelectOption || options === undefined);

  shouldAddSelectOption && options?.unshift([i18n.t('labels.select'), '']);

  const getValues = (target: unknown[] | SelectOptionsType) => {
    if(Array.isArray(target)) return target;

    return [target?.select_options_name, target?.select_options_value];
  };

  const getDataAttrs = (target: any[] | SelectOptionsType) => {
    if(!Array.isArray(target)) return target.select_options_data_attrs;
    if(Array.isArray(target) && target[2]) return target[2];
  };

  return (
    <select {...rest} {...register} ref={ref} disabled={disabledWithSkipValidation || rest.disabled}>
      <If condition={rest.placeholder}>
        <option value="All" hidden>{rest.placeholder}</option>
      </If>
      {(options || []).map((o, i) => <option key={i} value={getValues(o)[1]} {...getDataAttrs(o)}>{getValues(o)[0]}</option>)}
    </select>
  );
});

Select.displayName = 'Select';

export default Select;

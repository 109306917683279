import useDebounce from 'bundles/hooks/useDebounce';

const useSubmitFormDebounce = () => {
  const { debounceRequest } = useDebounce(undefined, 300);

  const withDebounce = (cb = () => {}) => {
    if(debounceRequest()) return;

    cb();
  };

  return { withDebounce };
};

export default useSubmitFormDebounce;

import { i18n } from 'bundles/utils';
import { formatBytes } from 'bundles/utils';

import parseFormattedAmount from 'bundles/utils/parseFormattedAmount';
import { setIdentifierPerCountry, testFileListWithRegExp, testFileListForSize, testValidDate, testQueryByEmails, testSortCode, checkMaxAmount } from './utils';
import { MYSQL } from 'bundles/constants';
import { LimitInterface } from 'bundles/components/common/Withdrawals/types';

const alphaNumericRegExp = /^([0-9a-zA-Z\s])*$/;
const alphabetRegExp = /[^\d\s]+/;
const addressRegExp = /^[^@()_]+$/;
const cityRegExp = /^[^0-9@()_]+$/;
const atLeastOneAlphaNumericRegExp = /^[^\d\s]+$/;
const emailRegExp = /^\s*([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
const ibanRegExp = /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{16}$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/i;
const bicNumberRegExp = /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/;
const ifscNumberRegExp = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
const phoneNumberRegExp = /^.{7,20}$/;
const bankCardTailNumberRegExp = /^[0-9]{4}$/;
const amountRegExp = (exponent?: number) => exponent ? new RegExp(`^(?![.0]*$)\\d+(?:\\.\\d{1,${exponent}})?$`) : /^(?![.0]*$)\d+$/;
const majorUnitAmountRegExp = /^(\+|-)?([0-9]{1,12})?(\.[0-9]{1,2})?$/;
const majorUnitAmountPositiveRegExp = /^(\+)?([0-9]{1,12})?(\.[0-9]{1,2})?$/;
const passwordPolicy = /^(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,30}$/;
const canadaSortCodeRegExp = /\d{5}-\d{3}$/;
const bankNumberRegExp = /^([0-9]{2}|[0-9]{4,6}|[0-9]{8})$/;
const nameFormatRegExp = /^[^0-9@()_]+$/;
const financialInstitutionNumberRegExp = /^\d{3}?$/;
const branchTransitNumberRegExp = /^\d{5}?$/;
const virtualPaymentAddressRegExp = /^[A-Za-z0-9]*@[A-Za-z0-9]*.$/;
const authCodeRegExp = /^[0-9]{6}$/;
const digitsRegExp = /^[0-9]+(,[0-9]+)*$/;
const percentileRegExp = /^([0-9]|[1-9][0-9]|100)$/;
const percentileRegExpWithDecimal = /^([0-9]|[1-9][0-9]|100)(\.[0-9]{1,2})?$/;
const floatNumberRegExp = /^[0-9]+(?:,[0-9]+)*(?:\.[0-9]+)?$/;
const nonZeroOrEmptyIntegerRegex = /^(?:[1-9]\d*|)$/;
const zeroAmountRegExp = (exponent?: number) => exponent
  ? new RegExp(`^(?:(?!0\\.00$)(?!\\.0*$)\\d+(?:\\.\\d{1,${exponent}})?|0(?:\\.\\d{1,${exponent}})?)$`)
  : /^(?:(?!0\.00$)(?!\.0*$)\d+|0(?:\.0)?)$/;

//EMAIL
export const isValidEmail = (value: string) => emailRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.email_format');

export const isValidQueryByEmails = (value: string) => testQueryByEmails(value, emailRegExp);

//BANK ACCOUNT
export const isIban = (value: string) => ibanRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_iban');

export const isBankNumber = (value: string) => bankNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_bank_number');

export const isBICNumber = (value: string) => bicNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_bic');

export const isIFSCNumber = (value: string) => ifscNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_ifsc');

export const isBankCardTailNumber = (value: string) => bankCardTailNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_bank_card_tail_number');

export const isFinancialInstitutionNumber = (value: string) => financialInstitutionNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_financial_institution_number');

export const isBranchTransitNumber = (value: string) => branchTransitNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_branch_transit_number');

//FILE UPLOAD
export const isFileRequired = (value: string) => value?.length ? undefined : i18n.t('messages.errors.forms.select_upload_file');

export const isPdfOrImgFormat = (value: string) => testFileListWithRegExp(value, /(\.pdf)|(\.png)|(\.jpe?g)$/i) ? undefined : i18n.t('messages.errors.forms.image_format');

export const isImgFormat = (value: string) => testFileListWithRegExp(value, /(\.png)|(\.jpe?g)$/i) ? undefined : i18n.t('messages.errors.forms.image_format');

export const isFileWithValidSize = (value: string, size: number) => testFileListForSize(value, size) ? undefined : i18n.t('messages.errors.forms.file_size_exceeded', { size: formatBytes(size) });

export const isCSVFormat = (value: string) => testFileListWithRegExp(value, /(\.csv)$/i) ? undefined : i18n.t('messages.errors.forms.csv_format');

//REQUIRED
export const isRequired = (value: string) => value ? undefined : i18n.t('messages.errors.forms.required');

export const isSelectRequired = (value: string) => value ? undefined : i18n.t('messages.errors.forms.required_selectbox');

export const isMultiSelectRequired = (value: string) => value?.length ? undefined : i18n.t('messages.errors.forms.required_selectbox');

export const isNumericValueRequired = (value: string) => digitsRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.numeric_field_required');

//MISCELLANEOUS
export const isAlphaNumeric = (value: string) => alphaNumericRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_alphanumeric');

export const isAtLeastOneAlphaNumeric = (value: string) => atLeastOneAlphaNumericRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.at_least_one_alphanumeric');

export const isAtLeastOneAlphaBet = (value: string) => alphabetRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.at_least_one_alphabet');

export const isAddressFormat = (value: string) => addressRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_address_format');

export const isCityFormat = (value: string) => cityRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_city_format');

export const isPdfFormat = (value: File[]) => /(\.pdf)$/i.test(value[0]?.name) ? undefined : i18n.t('messages.errors.forms.pdf_format');

export const isPercentileValueRequired = (value: string) => percentileRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.percentile_field_required');

export const isPercentileWithDecimalValue = (value: string) => percentileRegExpWithDecimal.test(value) ? undefined : i18n.t('messages.errors.forms.percentile_field_required_with_decimal');

export const isValidTinyMceLength = (value: string) => value.length > Number(MYSQL.TEXT_LENGTH) ? i18n.t('messages.errors.forms.test') : undefined;

export const isValidAmount = (value = '', exponent?: any) => amountRegExp(exponent).test(value) ? undefined : i18n.t('messages.errors.forms.invalid_amount');

export const isValidAmountWithZero = (value: string, exponent?: any)=> zeroAmountRegExp(exponent).test(value) ? undefined : i18n.t('messages.errors.forms.invalid_amount');

export const isValidMajorUnitAmount = (value: string) => majorUnitAmountRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_amount');

export const isValidMajorUnitAmountPositive = (value: string) => majorUnitAmountPositiveRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_amount');

export const isLessOrEqual = (value: string, compareValue: string) => parseFloat(value) > parseFloat(compareValue) ? undefined : i18n.t('messages.errors.forms.invalid_range');

export const isValidPassword = (value: string) => passwordPolicy.test(value) ? undefined : i18n.t('messages.errors.forms.password_format');

export const isUserRegistered = (value: string, preValue: string, message: string) => value !== preValue ? undefined : message;

export const isUsedRegistrationNumber = (value: string, prevValue: string, message: string) => value !== prevValue ? undefined : message;

export const isValidDocumentId = (value: string, country: string) => setIdentifierPerCountry(value, country) ? undefined : i18n.t(`labels.document_id.${country.toLowerCase()}.error_message`);

export const isValidNationalId = (value: string, country: string) => setIdentifierPerCountry(value, country) ? undefined : i18n.t(`labels.national_id.${country.toLowerCase()}.error_message`);

export const isValidVirtualPaymentAddress = (value: string) => virtualPaymentAddressRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_virtual_payment_address');

export const isValidAuthCode = (value: string) => authCodeRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_auth_code');

export const isEqualValues = (value: string, compareValue: string) => value === compareValue ? undefined : i18n.t('messages.errors.forms.password_equal_to');

export const isValidPhoneNumber = (value: string) => phoneNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_format');

export const isValidNameFormat = (value: string) => nameFormatRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_symbols_for_name');

export const isAmountMaximum = (value: string | undefined, details: Partial<LimitInterface>) => !details || checkMaxAmount(value, details) ? undefined : i18n.t('messages.errors.forms.invalid_amount_maximum', { amount: details.maximum_limit });

export const isAmountMinimum = (value: string | undefined, details: Partial<LimitInterface>) => !details || Number(value) >= parseFormattedAmount(details.minimum_limit, details) ? undefined : i18n.t('messages.errors.forms.invalid_amount_minimum', { amount: details.minimum_limit });

export const isInsufficientBalance = (value: string, compareValue: number) => Number(value) <= compareValue ? undefined : i18n.t('messages.errors.forms.insufficient_balance');

export const hasMaximumLength = (value: string, maxLength: number) => value.length > Number(maxLength) ? i18n.t('messages.errors.forms.maximum_length_exceeded', { length: maxLength }) : undefined;

export const hasMinimumLength = (value: string, compareValue: number) => value.length >= Number(compareValue) ? undefined : i18n.t('messages.errors.forms.minimum_length_required', { length: compareValue });

export const isValidDate = (value: string) => testValidDate(value) ? undefined : i18n.t('messages.errors.forms.invalid_date');

export const isValidSortCode = (value: string, country: string) => testSortCode(value, country, canadaSortCodeRegExp);

export const isValidMinAmount = (value: string, maximum_amount: string) =>  parseFloat(value) > parseFloat(maximum_amount) ?  i18n.t('messages.errors.merchant_limits.min_value_validation') : undefined;

export const isValidMaxAmount = (value: string, minimum_amount: string) =>  parseFloat(value) < parseFloat(minimum_amount) ? i18n.t('messages.errors.merchant_limits.max_value_validation') : undefined;

export const isValidFloatNumberValue = (value: string) => floatNumberRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.numeric_field_required');

export const isValidAccountId = (value: string, valid_account_id: string) => valid_account_id ? undefined : i18n.t('messages.errors.forms.transfers.invalid_account_id');

export const isStrictlyPositiveNumber = (value: string) => Number(value) > 0 ? undefined : i18n.t('messages.errors.forms.invalid_amount');

export const hasValidNumericValue = (value: string) => value === null || nonZeroOrEmptyIntegerRegex.test(value) ? undefined : i18n.t('messages.errors.forms.numeric_field_required');
